import React from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {display, m} from "@pg-design/helpers-css";
import {ChevronDownIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {OfferType} from "../offer/types/OfferType";
import {propertiesSortOptions} from "./PropertyListTable";

interface IProps {
    offer: {
        price_type: number | null;
        type: OfferType;
    };
    onSortChange: (value: string) => void;
    sort: string | null;
    showPriceColumn: boolean;
    hideCell?: {
        propertyPlan?: boolean;
        propertyNumber?: boolean;
        propertyRooms?: boolean;
        propertyArea?: boolean;
        plotArea?: boolean;
        propertyFloor?: boolean;
        propertyPrice?: boolean;
    };
    comparisonMode?: boolean;
}

type TSortDirection = "asc" | "desc";

export const PropertyListTableHeader: React.FC<IProps> = (props) => {
    const propertyListHeaders = () => [
        // number
        propertiesSortOptions.NUMBER,
        // rooms - conditional
        ...(props.offer.type === OfferType.HOUSE || props.offer.type === OfferType.FLAT ? [propertiesSortOptions.ROOMS] : []),
        // area
        propertiesSortOptions.AREA,
        // floor OR plot_area
        props.offer.type === OfferType.HOUSE ? propertiesSortOptions.PLOT_AREA : propertiesSortOptions.FLOOR,
        // price
        ...(props.showPriceColumn ? [propertiesSortOptions.PRICE] : [])
    ];

    const onHeaderClick = (value: string) => {
        const finalValue = props.sort === value ? `-${value}` : value;
        props.onSortChange(finalValue);
    };

    const getHideValue = (type: string) => {
        switch (type) {
            case "number":
                return props.hideCell?.propertyNumber;
            case "rooms":
                return props.hideCell?.propertyRooms;
            case "area":
                return props.hideCell?.propertyArea;
            case "floor":
                return props.hideCell?.propertyFloor;
            case "price":
                return props.hideCell?.propertyPrice;
            default:
                return false;
        }
    };

    const theme = useTheme();

    return (
        <div css={rowStyle}>
            <HeaderCell showOnMobile hideCell={props.hideCell?.propertyPlan}>
                <Text align="left" variant="body_copy_1" as="span" css={[styleOverrides, display("block")]}>
                    Rzut
                </Text>
            </HeaderCell>
            {propertyListHeaders().map((header) => {
                const isActive = props.sort ? header.value === props.sort || `-${header.value}` === props.sort : false;
                const sortDirection: TSortDirection = isActive && props.sort && props.sort.includes("-") ? "desc" : "asc";

                return (
                    <HeaderCell
                        showOnMobile={header.showOnMobile}
                        hideCell={getHideValue(header.value)}
                        sortable
                        sortDirection={sortDirection}
                        isActive={isActive}
                        key={header.value}
                        onClick={() => onHeaderClick(header.value)}
                    >
                        {props.comparisonMode && header.value === "price" ? (
                            <Text variant="body_copy_1" as="span" css={styleOverrides}>
                                Porównywarka
                            </Text>
                        ) : (
                            <>
                                <Text variant="body_copy_1" as="span" css={styleOverrides}>
                                    {header.shortName}
                                </Text>
                                <IconWrapper sortDirection={sortDirection}>
                                    <ChevronDownIcon size="1.3" fill={theme.colors.gray[700]} />
                                </IconWrapper>
                            </>
                        )}
                    </HeaderCell>
                );
            })}
        </div>
    );
};

interface IHeaderCell {
    isActive?: boolean;
    sortDirection?: TSortDirection;
    sortable?: boolean;
    showOnMobile?: boolean;
    hideCell?: boolean;
}

const HeaderCell = styled.div<IHeaderCell>`
    padding: 0 0.5rem;
    height: 54px;
    vertical-align: middle;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    text-align: center;
    white-space: nowrap;

    &:first-of-type {
        padding: 1rem 1.4rem 1rem 1.2rem;
        text-align: left;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
        height: 64px;

        &:first-of-type {
            padding: 1rem 2.8rem;
        }
    }

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}

    ${(props) => css`
        display: ${props.showOnMobile && !props.hideCell ? "table-cell" : "none"};
        @media (min-width: ${props.theme.breakpoints.sm}) {
            display: ${props.hideCell ? "none" : "table-cell"};
        }
    `}
`;

interface IIconWrapper {
    sortDirection: TSortDirection;
}

const IconWrapper = styled.span<IIconWrapper>`
    ${m(0, 0, 0, 0.5)};

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
        ${m(0, 0, 0, 1)};
    }
    > svg {
        transform: ${(props) => (props.sortDirection === "asc" ? `rotate(180deg) scale(1.3)` : "")};
    }
    ${(props) =>
        props.sortDirection === "asc"
            ? css`
                  & > svg {
                      transform: rotate(180deg) scale(1.5);
                  }
              `
            : ""};
`;

const rowStyle = css`
    width: 100%;
    display: table-row;
`;

const styleOverrides = css`
    font-size: 1.35rem;
`;
